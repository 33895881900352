<template>
  <BaseModal
    name="modal-integracoes_club"
    size="lg"
    :title="$t('seller.products.greenn_club.text_385')"
    @shown="openModal"
    @hidden="clearFields"
  >
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <section class="container-arquivos" v-else>
      <div class="lista-entrega lista-arquivos mt-4">
        <!-- Voomp Play -->
        <div>
          <div class="d-flex justify-content-between">
            <h4 class="title-inter">• Voomp Play</h4>
            <div v-if="userOk" class="action-club">
              <BaseButton
                variant="secondary_outline"
                class="novo-projeto mb-2"
                @click="toggleNewClub"
                >{{ newClub.flag_name }}</BaseButton
              >
              <BaseButton
                variant="secondary_outline"
                class="btn-entrega button-info3 novo-projeto"
                @click="loginClub"
                v-if="!newClub.flag && !$store.getters.setPanel"
              >
                {{ $t("seller.products.greenn_club.text_386") }}
              </BaseButton>
            </div>
          </div>
          <b-form data-vv-scope="club" v-if="userOk">
            <b-row>
              <b-col cols="12" md="6" v-if="newClub.flag" data-anima="top">
                <b-form-group
                  :label="$t('seller.products.greenn_club.text_387')"
                  label-for="project_name"
                >
                  <b-form-input
                    v-model="newClub.name"
                    name="project_name"
                    id="project_name"
                    :placeholder="$t('seller.products.greenn_club.text_1919')"
                    v-validate="'required'"
                    autocomplete="off"
                  />
                  <b-form-invalid-feedback
                    :state="!errors.has('club.project_name')"
                  >
                    {{ $t("seller.products.greenn_club.text_388") }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  :label="$t('seller.products.greenn_club.text_389')"
                  label-for="subdomain"
                >
                  <b-form-input
                    v-model="newClub.subdomain"
                    name="subdomain"
                    id="subdomain"
                    autocomplete="off"
                    :placeholder="$t('seller.products.greenn_club.text_390')"
                    v-validate="{
                      required: true,
                      regex: /^[a-z\-_0-9]{6,30}$/i,
                    }"
                  />
                  <p class="sub-dominio">
                    {{ newClub.subdomain }}.voompplay.com.br
                  </p>
                  <b-form-invalid-feedback
                    :state="!errors.has('club.subdomain')"
                  >
                    {{ $t("seller.products.greenn_club.text_391") }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <!--<div class="mt-4">
                  <b-form-group
                    :label="$t('seller.products.greenn_club.text_392')"
                    label-for="create_demo"
                  >
                    <b-form-checkbox
                      v-model="newClub.create_demo"
                      name="create_demo "
                      size="lg"
                      switch
                    >
                      <p class="info-checkbox">
                        {{ $t("seller.products.greenn_club.text_393") }}
                        <span class="descricao-checkbox">{{
                          $t("seller.products.greenn_club.text_394")
                        }}</span>
                      </p>
                    </b-form-checkbox>
                  </b-form-group>
                </div>-->
              </b-col>

              <b-col cols="12" md="6" data-anima="top" v-if="!newClub.flag">
                <b-form-group
                  :label="$t('seller.products.greenn_club.text_395')"
                  label-for="projeto"
                >
                  <b-form-select
                    v-model="club.site_id"
                    :options="club.options_projetos"
                    name="projeto"
                    id="projeto"
                    :placeholder="$t('seller.products.greenn_club.text_396')"
                    v-validate="'required'"
                    @change="changeProjeto"
                  >
                  </b-form-select>
                  <b-form-invalid-feedback :state="!errors.has('club.projeto')">
                    {{ $t("seller.products.greenn_club.text_396") }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  :label="$t('seller.products.greenn_club.text_1920')"
                  label-for="tipo"
                  v-show="club.site_id"
                >
                  <b-form-select
                    v-model="club.data.access"
                    :options="club.options_conexao_tipo"
                    @input="handleTypeChange"
                    name="tipo"
                    id="tipo"
                    :placeholder="$t('seller.products.greenn_club.text_1921')"
                    v-validate="'required'"
                    @change="changeClub"
                  >
                  </b-form-select>
                  <b-form-invalid-feedback :state="!errors.has('club.tipo')">
                    {{ $t("seller.products.greenn_club.text_397") }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <div v-if="!newClub.flag && club.data.access">
              <div v-show="club.options.length">
                <b-form-group
                  :label="$t('seller.products.greenn_club.text_398')"
                  label-for="classroom"
                  v-if="club.data.access == 'course_class'"
                  data-anima="top"
                >
                  <multiselect
                    v-model="club.data.course_class_id"
                    :options="club.options"
                    :value="club.data.course_class_id"
                    @input="handleSelectionChange"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :placeholder="$t('seller.products.greenn_club.text_399')"
                    label="text"
                    track-by="text"
                    selectLabel=""
                    deselectLabel=""
                    selectedLabel="✔️"
                    id="classroom"
                    name="classroom"
                    v-validate="'required'"
                  >
                  </multiselect>
                  <a
                    v-if="!checkText()"
                    href="#"
                    @click.prevent="selecionarTodos('course_class')"
                    class="legenda-form-tp"
                    >{{ $t('seller.products.greenn_club.text_419') }}
                    </a
                  >
                  <a
                    v-else
                    href="#"
                    @click.prevent="removerTodos('course_class')"
                    class="legenda-form-tp"
                    >{{ $t('seller.products.greenn_club.text_420') }}
                    </a
                  >
                  <p class="legenda-form">
                    • {{ $t("seller.products.greenn_club.text_400") }}
                  </p>
                  <b-form-invalid-feedback
                    :state="!errors.has('club.classroom')"
                  >
                    {{ $t("seller.products.greenn_club.text_401") }}
                  </b-form-invalid-feedback>
                </b-form-group>
            
                <b-form-group
                  :label="$t('seller.products.greenn_club.text_402')"
                  label-for="membership_id"
                  v-if="club.data.access == 'signature'"
                  data-anima="top"
                >
                  <multiselect
                    v-model="club.data.signature_ids"
                    :options="club.options"
                    :value="club.data.signature_ids"
                    @input="handleSelectionChange"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :placeholder="$t('seller.products.greenn_club.text_402')"
                    label="text"
                    track-by="text"
                    selectLabel=""
                    deselectLabel=""
                    selectedLabel="✔️"
                    class="multi-select-input"
                    name="membership_id"
                    id="membership_id"
                    v-validate="'required'"
                  >
                  </multiselect>
                  <a
                    v-if="!checkText()"
                    href="#"
                    @click.prevent="selecionarTodos('signature')"
                    class="legenda-form-tp"
                    >{{ $t('seller.products.greenn_club.text_419') }}</a
                  >
                  <a
                    v-else
                    href="#"
                    @click.prevent="removerTodos('signature')"
                    class="legenda-form-tp"
                    >{{ $t('seller.products.greenn_club.text_420') }}
                    </a
                  >

                  <p class="legenda-form">
                    • {{ $t("seller.products.greenn_club.text_403") }}
                  </p>
                  <b-form-invalid-feedback
                    :state="!errors.has('club.data.membership_id')"
                  >
                    {{ $t("seller.products.greenn_club.text_404") }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
              <div  v-show="!club.options.length">
                <p class="legenda" data-anima="bottom">
                  {{ $t("seller.products.greenn_club.text_405") }}
                </p>
              </div>
            </div>
          </b-form>
          <div v-else class="dados-incompletos" data-anima="bottom">
            <h6>{{ $t("seller.products.greenn_club.text_406") }}</h6>
            <p>
              {{ $t("seller.products.greenn_club.text_407") }}
            </p>
            <p>
              {{ $t("seller.products.greenn_club.text_408") }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <template v-slot:footer="{ cancel }">
      <BaseButton
        :disabled="loading"
        variant="secondary_outline"
        class="mr-4"
        @click="cancel"
      >
        {{ $t("seller.products.greenn_club.text_1922") }}
      </BaseButton>

      <BaseButton
        variant="secondary"
        :disabled="hasContent || loading"
        @click="adicionarClub"
        v-if="userOk && !newClub.flag"
      >
        {{ $t("seller.products.greenn_club.text_409") }}
      </BaseButton>
      <BaseButton
        variant="secondary"
        :disabled="loading || errors.has('club.subdomain')"
        @click="novoClub"
        v-if="userOk && newClub.flag"
      >
        {{ $t("seller.products.greenn_club.text_410") }}
      </BaseButton>

      <BaseButton
        variant="info3"
        :disabled="loading"
        @click="atualizarConta"
        v-if="!userOk"
      >
        {{ $t("seller.products.greenn_club.text_411") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import Multiselect from "vue-multiselect";
import Cookies from "js-cookie";

// services:
import ClubService from "@/services/resources/ClubService";
const serviceClub = ClubService.build();

import IntegrationService from "@/services/resources/IntegrationService";
const serviceIntegration = IntegrationService.build();

export default {
  data() {
    return {
      loading: false,
      submit: false,
      dados_user: {},
      userOk: true,
      flag_novo: false,
      hasContent: true,
      club: {
        data: {
          access: null,
          type: "club",
          product_id: null,
        },
        options_projetos: [
          {
            value: null,
            text: this.$t("seller.products.greenn_club.text_1923"),
          },
        ],
        site_id: "",
        options_conexao_tipo: [
          {
            value: null,
            text: this.$t("seller.products.greenn_club.text_1923"),
          },
          {
            value: "course_class",
            text: this.$t("seller.products.greenn_club.text_1924"),
          },
          {
            value: "signature",
            text: this.$t("seller.products.greenn_club.text_1925"),
          },
        ],
        options_conexao: [],
        options: [],
      },
      newClub: {
        product: {
          id: "",
          name: "",
        },
        flag_name: this.$t("seller.products.greenn_club.text_410"),
        flag: false,
        name: "",
        course: "",
        subdomain: "",
        create_demo: false,
      },
      regrasLiberacao: [],
    };
  },
  components: {
    Multiselect,
  },
  props: {
    id_product: {
      type: Number,
    },
    name_product: {
      type: String,
    },
  },
  computed: {
    clubIntegration() {
      return this.$store.getters.userClub;
    }
  },
  methods: {
    handleTypeChange(){
      this.hasContent = true;
    },
    handleSelectionChange(value) {
      this.hasContent = !(typeof(value) !== 'undefined' && value !== null && value.length > 0);
    },
    selecionarTodos(data) {
      if(data == "course_class"){
          this.club.data.course_class_id = [...this.club.options].map((item) => ({
            value: item.value,
            text: item.text
          }));
          this.handleSelectionChange(this.club.data.course_class_id);
      }else{
          this.club.data.signature_ids = [...this.club.options].map((item) => ({
            value: item.value,
            text: item.text
          }));
          this.handleSelectionChange(this.club.data.signature_ids);
      }
      this.$forceUpdate()
    },
    removerTodos(data){
      if(data == "course_class"){
          this.club.data.course_class_id = null;
          this.handleSelectionChange(this.club.data.course_class_id);
      }else{
          this.club.data.signature_ids = null;
          this.handleSelectionChange(this.club.data.signature_ids);
      }
      this.$forceUpdate()
    },
    checkText(){
      if (typeof(this.club.data.course_class_id) !== 'undefined' && this.club.data.course_class_id !== null) {
        return (this.club.data.course_class_id.length === this.club.options.length);
      }

      if (typeof(this.club.data.signature_ids) !== 'undefined'  && this.club.data.signature_ids !== null) {
        return (this.club.data.signature_ids.length === this.club.options.length);
      }

      return false;
    },
    loginClub() {
      this.$store.dispatch("clubLogin").catch(function(err) {});
    },
    // btn para atualizar os dados da conta
    atualizarConta() {
      open("/minha-conta");
      this.$bvModal.hide("modal-integracoes_club");
    },
    // criando um novo projeto no club
    novoClub() {
      this.$validator.validateAll("club").then((result) => {
        if (result) {
          this.submit = true;
          this.loading = true;
          const id_product = this.id_product;
          const name_product = this.name_product;

          let data = {
            project_name: `#${id_product} - ${this.newClub.name}`,
            course_title: name_product,
            create_demo: this.newClub.create_demo || false,
            subdomain: this.newClub.subdomain,
          };
          // informando o usuario sobre a demora na criação do projeto
          this.$bvToast.toast(this.$t("seller.products.greenn_club.text_412"), {
            title: "Voomp Play",
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });

          // iniciando a criação do projeto
          serviceClub
            .create(data)
            .then((response) => {
              if (response.success) {
                // fechando modal para não bloquear o fluxo do usuario
                // this.$bvModal.hide("modal-integracoes_club");

                this.$store
                  .dispatch("clubVerification")
                  .catch(function(err) {});

                this.$bvToast.toast(
                  this.$t("seller.products.greenn_club.text_413"),
                  {
                    title: "Voomp Play",
                    variant: "success",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );

                this.flag_novo = true;
                this.toggleNewClub();
                this.clearFields();
                this.fetchProjetos();
              } else {
                this.$bvToast.toast(
                  this.$t("seller.products.greenn_club.text_414"),
                  {
                    title: "Voomp Play",
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
              }
              this.loading = false;
              this.submit = false;
            })
            .catch((error) => {
              this.loading = false;
              this.submit = false;
              console.log(error);
            });
        }
      });
    },

    // adicinando novo item ao club
    adicionarClub() {
      this.submit = true;
      this.loading = true;
      this.club.data.product_id = this.id_product;
      if (this.club.data.access == "signature") {
        // separando names
        this.club.data.signature_names = [...this.club.data.signature_ids].map(
          (item) => item.text
        );

        // separando IDs
        this.club.data.signature_ids = [...this.club.data.signature_ids].map(
          (item) => item.value
        );
      } else {
        // Separando names
        this.club.data.course_class_names = [
          ...this.club.data.course_class_id,
        ].map((item) => item.text);

        // separando IDs
        this.club.data.course_class_ids = [
          ...this.club.data.course_class_id,
        ].map((item) => item.value);
      }

      this.$validator.validateAll("club").then((result) => {
        if (result) {
          serviceIntegration
            .create(this.club.data)
            .then((response) => {
              if (response.success) {
                this.$store
                  .dispatch("clubVerification")
                  .catch(function(err) {});
                this.$bvToast.toast(
                  this.$t("seller.products.greenn_club.text_415"),
                  {
                    title: "Voomp Play",
                    variant: "success",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
                this.$bvModal.hide("modal-integracoes_club");
                this.$emit("close");
                this.clearFields();
              } else {
                this.$bvToast.toast(
                  this.$t("seller.products.greenn_club.text_416"),
                  {
                    title: "Voomp Play",
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
              }
              this.loading = false;
              this.submit = false;
            })
            .catch((error) => {
              this.loading = false;
              this.submit = false;
              console.log(error);
            });
        } else {
          this.loading = false;
          this.submit = false;
        }
      });
    },

    // evento change no select
    changeClub(event, flag = false) {
      delete this.club.data.course_class_id;
      delete this.club.data.signature_ids;

      this.fetchIntegrationClub(flag);
    },

    // puxando os itens do club
    fetchIntegrationClub(flag = false) {
      this.loading = true;
      let data = {
        type: "club",
        access: this.club.data.access,
        site_id: this.club.site_id,
      };

      serviceIntegration
        .search(data)
        .then((response) => {
          this.club.options = [];
          if (this.club.data.access == "signature") {
            response.forEach((item) => {
              this.club.options.push({
                value: item.signature_id,
                text: ` ${item.site_name} | ${item.signature_title}`,
              });
            });
          } else {
            response.forEach((item) => {
              this.club.options.push({
                value: item.course_class_id,
                text: ` ${item.site_name} | ${item.course_title} | ${item.course_class_title}`,
              });
            });
          }

          if (this.flag_novo && !flag) {
            this.flag_novo = false;
          }
          if (!flag) {
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    // flag club novo
    toggleNewClub() {
      this.newClub.flag = !this.newClub.flag;
      if (this.newClub.flag)
        this.newClub.flag_name = this.$t(
          "seller.products.greenn_club.text_417"
        );
      if (!this.newClub.flag)
        this.newClub.flag_name = this.$t(
          "seller.products.greenn_club.text_410"
        );
    },

    // limpa os campos / reseta
    clearFields() {
      this.club.data.access = null;
      this.club.options = [];
      this.club.site_id = null;
      this.club.data.course_class_id = null;
      this.club.data.signature_ids = null;
      this.loading = false;
      this.newClub = {
        product: {
          id: "",
          name: "",
        },
        flag_name: this.$t("seller.products.greenn_club.text_410"),
        flag: false,
        name: "",
        course: "",
        subdomain: "",
        create_demo: false,
      };
    },

    changeProjeto(value) {
      this.club.site_id = value;
      this.club.data.access = null;

      this.$forceUpdate();
    },

    fetchProjetos() {
      this.loading = true;
      serviceClub
        .search()
        .then((response) => {
          this.$store.dispatch("clubVerification").catch(function(err) {});
          if (response.length) {
            this.club.options_projetos = [
              {
                value: null,
                text: this.$t("seller.products.greenn_club.text_1923"),
              },
            ];
            [...response].forEach((item) => {
              this.club.options_projetos.push({
                value: item.id,
                text: item.name,
              });
            });

            if (this.flag_novo) {
              this.club.site_id = null;
              this.club.site_id = this.club.options_projetos[
                this.club.options_projetos.length - 1
              ].value;

              this.club.data.access = "signature";
              this.changeClub(this.club.data.access, true);

              setTimeout(() => {
                this.club.data.access = "course_class";
                this.changeClub();
              }, 1000);

            }
          } else {
            this.$bvToast.toast(
              this.$t("seller.products.greenn_club.text_418"),
              {
                title: "Voomp Play",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
            this.toggleNewClub();
            setTimeout(() => {
              if (document.querySelector("#project_name")) {
                document.querySelector("#project_name").focus();
              }
            }, 1000);
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$bvToast.toast(this.$t("seller.products.greenn_club.text_418"), {
            title: "Voomp Play",
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.toggleNewClub();
          setTimeout(() => {
            if (document.querySelector("#project_name")) {
              document.querySelector("#project_name").focus();
            }
          }, 1000);
        })
        .finally(() => {
          if (!this.flag_novo) this.loading = false;
        });
    },

    // abrindo modal e setando valores
    openModal() {
      // this.dados_user = JSON.parse(Cookies.get("user"));
      this.hasContent = true;
      this.dados_user = Object.assign({}, this.$store.state.user.user);
      if (
        this.dados_user.name &&
        this.dados_user.email &&
        this.dados_user.cellphone
      ) {
        this.userOk = true;
        // puxandos projetos
        this.fetchProjetos();
      } else {
        this.userOk = false;
      }

      // if(this.dados_user.name)
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
}
.title-inter {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  color: $black;
}
.form-group + .form-group {
  margin-top: 15px;
}
/* // codigo de verificação g digital */
.codigo-verifica {
  max-width: 60%;
}
@media screen and (max-width: 768px) {
  .codigo-verifica {
    max-width: 100%;
  }
}
.codigo-verifica p {
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
  margin-bottom: 20px;
}
.sem-codigo {
  font-size: 14px;
  color: #333;
}
.sem-codigo:hover {
  font-weight: normal;
}
.grid-events {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 20px 0 10px;
}
.titulo-acao {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
}

.check-box .info-checkbox {
  font-size: 13px;
  font-weight: 500;
  margin-left: 5px;
  cursor: pointer;
  max-width: 250px;
  margin-top: 15px !important;
}
.sub-dominio {
  font-weight: normal;
  color: #81858e;
  font-size: 13px;
  line-height: 1.5;
  margin-top: 5px;
}
/* legenda */
.legenda {
  color: #81858e;
  font-size: 14px;
  margin: 0;
  margin-top: 20px;
  text-align: center;
}
.legenda-form {
  color: #81858e;
  font-size: 13px;
  margin: 0;
  position: absolute;
  /* top: 0; */
  right: 0;
}

.legenda-form-tp{
  color: #002E74;
  font-size: 13px;
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
  text-decoration: underline;
  line-height: 15.73px;
  font-weight: 400;
}

/* dados incompletos */
.dados-incompletos h6 {
  font-weight: 600;
  font-size: 16px;
}
.dados-incompletos p {
  color: #81858e;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 5px;
  font-weight: normal;
}

/* btn novo projeto */
.novo-projeto {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}

.action-club {
  display: grid;
}
</style>
